<template>
    <v-app>
        <v-app-bar
            app
            color="white"
            flat
            >
            <v-avatar
                :color="$vuetify.breakpoint.smAndDown ? 'grey darken-1' : 'transparent'"
                size="32"
            ></v-avatar>
            <v-tabs
                centered
                class="ml-n9"
                color="blue darken-1"
            >
                <v-tab
                v-for="link in links"
                :key="link.path"
                :to="link.path"
                >
                {{ link.name }}
                </v-tab>
            </v-tabs>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                        class="hidden-sm-and-down white--text"
                        color="teal darken-1 shrink"
                        size="36"
                        v-bind="attrs"
                        v-on="on"
                    >
                    <v-icon color="white" x-small>fas fa-user</v-icon>
                    </v-avatar>
                </template>
                <v-list>
                    <v-list-item @click="logout" color="blue">
                        <v-list-item-icon class="mr-1">
                            <v-icon small>fas fa-sign-out-alt</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title >Wyloguj się</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-main class="grey lighten-3">
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'AppLayout',
    data: () => ({
        links: [
            {name: 'Zawody', path: '/'},
            {name: 'Uczniowie', path: '/uczniowie'},
            {name: 'Dyscypliny', path: '/dyscypliny'},
        ]
    }),
    methods:{
        logout:function(){
            this.$store.dispatch('user/logout');
        }
    }
}
</script>

<style>

</style>